<template>
  <el-row v-if="isMobileView" class="mobile-navmenu">
    <el-button v-if="isMobileView" @click="openMenuDrawer" :with-header="false">
      <el-icon><Expand /></el-icon>
    </el-button>
    <img @click="hanldeLogoClick" src="../assets/logo/logo.svg" />
    <el-drawer v-model="menuDrawer" direction="ltr">
      <template #header>
        <img @click="hanldeLogoClick" src="../assets/logo/logo.svg" />
      </template>
      <el-menu
        :default-active="activeIndex"
        mode="vertical"
        :ellipsis="false"
        @select="handleSelect"
      >
        <el-menu-item index="1">Home</el-menu-item>
        <el-menu-item index="2">Features</el-menu-item>
        <el-menu-item index="3">Our Team</el-menu-item>
        <el-menu-item v-if="isLogin" index="4" class="logout-button">
          <span>
            <el-button @click="hanldeLogOut" size="small" round>
              Log out
            </el-button>
          </span>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </el-row>
  <el-menu
    v-else
    :default-active="activeIndex"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
  >
    <img @click="hanldeLogoClick" src="../assets/logo/logo.svg" />
    <el-row class="flex-grow" />
    <el-menu-item index="1">Home</el-menu-item>
    <el-menu-item index="2">Features</el-menu-item>
    <el-menu-item index="3">Our Team</el-menu-item>
    <el-menu-item v-if="isLogin" index="4" class="logout-button">
      <span>
        <el-button @click="hanldeLogOut" size="small" round>
          Log out
        </el-button>
      </span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import "../assets/styles/app-header.css"
import { ref, computed, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import _ from "lodash"
import { ElMessage } from "element-plus"
export default {
  setup() {
    const menuDrawer = ref(false)
    const isLogin = ref(true)
    const activeIndex = ref("1")

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const isMobileView = computed(() => store.getters["auth/getIsMobileView"])
    const loginStatus = computed(() => store.getters["auth/getLoginStatus"])
    const jwtToken = computed(() => store.getters["auth/getJwtToken"])

    onMounted(() => {
      console.log(isMobileView.value)
      isLogin.value =
        loginStatus.value === "success" ||
        !_.isEmpty(localStorage.getItem("token"))
          ? true
          : false
      if (
        route.path === "/" ||
        route.path === "/login" ||
        route.path === "/login-authentication"
      ) {
        activeIndex.value = "1"
      } else if (route.path === "/features") {
        activeIndex.value = "2"
      } else if (route.path === "/our-team") {
        activeIndex.value = "3"
      } else {
        activeIndex.value = null
      }
    })

    const openMenuDrawer = () => {
      console.log("openMenuDrawer")
      menuDrawer.value = true
    }

    const hanldeLogoClick = () => {
      if (route.path === "/" || route.path === "/login") {
        return
      } else if (
        loginStatus.value === "success" ||
        !_.isEmpty(localStorage.getItem("token"))
      ) {
        return router.push("/")
      } else {
        return router.push("/login")
      }
    }
    const handleSelect = async (key) => {
      menuDrawer.value = false
      if (key === "1") {
        if (route.path === "/" || route.path === "/login") {
          return
        } else if (
          loginStatus.value === "success" ||
          !_.isEmpty(localStorage.getItem("token"))
        ) {
          return router.push("/")
        } else {
          return router.push("/login")
        }
      } else if (key === "2") {
        return router.push("/features")
      } else if (key === "3") {
        return router.push("/our-team")
      } else if (key === "4") {
        hanldeLogOut()
      }
    }

    const hanldeLogOut = async () => {
      try {
        await store.dispatch("auth/userLogOut", jwtToken.value)
      } catch (error) {
        ElMessage({
          type: "error",
          message: error.message,
        })
        return this.$router.push("/login")
      }
      return this.$router.push("/login")
    }

    return {
      isMobileView,
      menuDrawer,
      openMenuDrawer,
      isLogin,
      activeIndex,
      hanldeLogoClick,
      handleSelect,
      hanldeLogOut,
    }
  },
}
</script>
