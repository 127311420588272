import { createRouter, createWebHistory } from 'vue-router'
import _ from 'lodash'
import store from "../store/index"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/AppHome.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/AppLogin.vue'),
    meta: { requiredLogin: true }
  },
  {
    path: '/login-authentication',
    name: 'LoginAuthentication',
    component: () => import('../views/LoginAuthentication.vue'),
    meta: { requiredTwoFactor: true }
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import('../views/AppFeatures.vue')
  },
  {
    path: '/our-team',
    name: 'OurTeam',
    component: () => import('../views/OurTeam.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach((to, _1, next) => {
  let loginStatus = store.getters[ "auth/getLoginStatus" ]
  let isTwoFactor = store.getters[ "auth/getIsTwoFactor" ]
  console.log(
    "logIn Status in beforeEach function in router js =====>",
    loginStatus
  )
  console.log(
    "isTwoFactor in beforeEach function in router js =====>",
    isTwoFactor
  )

  if (to.meta.requiredAuth)
  {
    if (
      (!_.isEmpty(loginStatus) && loginStatus !== "success") ||
      _.isEmpty(localStorage.getItem("token"))
    )
    {
      return next({ path: "/login" })
    }
  } else
  {
    return next()
  }

  if (to.meta.requiredLogin)
  {
    if (
      (!_.isEmpty(loginStatus) && loginStatus === "success" && !isTwoFactor) ||
      !_.isEmpty(localStorage.getItem("token"))
    )
    {
      console.log("Route to Root/Home Path and Login Succeeded")
      return next({ path: "/" })
    } else if (isTwoFactor)
    {
      console.log(
        "Route to Two Factor Authentication Path and Two Factor Authentication is required"
      )
      return next({ name: "LoginAuthentication" })
    }
    else
    {
      return next()
    }
  }

  if (to.meta.requiredTwoFactor)
  {
    if (!isTwoFactor)
    {
      console.log(
        "Route to Login Path and Two Factor Authentication is not required"
      )
      return next({ path: "/" })
    }

    next()
  }
})

export default router