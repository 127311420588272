import axios from "axios"

const state = () => ({
  isMobileView: window.innerWidth < 768 ? true : false,
  isLoading: false,
  loginStatus: null,
  isTwoFactor: false,
  logInCredentials: {},
  jwtToken: null,
  logOut: false,
})

const getters = {
  getIsMobileView: (state) => state.isMobileView,
  getIsLoading: (state) => state.isLoading,
  getLoginStatus: (state) => state.loginStatus,
  getLogInCredentials: (state) => state.logInCredentials,
  getIsTwoFactor: (state) => state.isTwoFactor,
  getJwtToken: (state) => state.jwtToken,
  getLogOut: (state) => state.logOut,
}

const actions = {
  changeIsLoading ({ commit }, payload) {
    commit("setIsLoading", payload)
  },
  async login ({ commit }, payload) {
    commit("setLogInCredentials", payload)
    const response = await axios
      .post(
        "https://api-insta-archive.herokuapp.com/api/v1/userlogin",
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin":
              "https://insta-archive-webapp.pages.dev",
          },
        }
      )
      .catch((err) => {
        throw err
      })
    console.log("Actual Response From Server ====>", response)
    console.log("Response.data ====>", response.data)
    console.log("JWT Token (response.data.data) ====>", response.data.data)
    console.log(response.data.status)
    if (
      response &&
      (response.data.status === "STATE-100" ||
        response.data.status === "STATE-000")
    )
    {
      commit("setLoginStatus", "success")
      localStorage.setItem("loginStatus", "success")
      commit("setResponseData", response.data.data)
      localStorage.setItem("token", response.data.data)
      console.log("loged in")
    } else if (response && response.data.status === "STATE-049")
    {
      commit("setIsTwoFactor", true)
      commit("setLoginStatus", "failed")
      console.log("Two Factor Authentication is Required")
    } else
    {
      commit("setLoginStatus", "failed")
    }
  },
  async sendOTP ({ commit }, payload) {
    const response = await axios
      .post(
        "https://api-insta-archive.herokuapp.com/api/v1/userlogin",
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .catch((err) => {
        throw err
      })
    console.log(
      "Response from Server For Two Factor Authentication ====>",
      response
    )
    if (response && response.data.status === "STATE-050")
    {
      throw new Error("Invalid OTP")
    }
    if (response && response.data.status === "STATE-000")
    {
      commit("setLoginStatus", "success")
      localStorage.setItem("loginStatus", "success")
      commit("setResponseData", response.data.data)
      localStorage.setItem("token", response.data.data)
      console.log("Log in Successfull")
      commit("removeLoginCredentials")
      commit("setIsTwoFactor", false)
    } else
    {
      commit("setLoginStatus", "failed")
    }
  },
  async userLogout ({ commit }, token) {
    const response = await axios
      .delete("https://api-insta-archive.herokuapp.com/api/v1/userlogout", {
        headers: {
          authorization: token || localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      })
      .catch((err) => {
        console.log("LogOut Error Response =====>", err)
        throw err
      })
    console.log("LogOut Response =====>", response)
    if (
      response &&
      (response.data.status === "STATE-000" ||
        response.data.status === "STATE-004" ||
        response.data.status === "STATE-005" ||
        response.data.status === "STATE-006")
    )
    {
      commit("setLogOut", true)
    } else
    {
      commit("setLogOut", false)
    }
  },
}

const mutations = {
  setIsLoading (state, payload) {
    state.isLoading = payload
  },
  setLoginStatus (state, payload) {
    state.loginStatus = payload
  },
  setLogInCredentials (state, payload) {
    state.logInCredentials = payload
  },
  setIsTwoFactor (state, payload) {
    state.isTwoFactor = payload
  },
  setJwtToken (state, payload) {
    console.log("Setting Response Data to Vuex Store ====>", payload)
    state.jwtToken = payload
  },
  setLogOut (state, payload) {
    state.jwtToken = ""
    state.loginStatus = null
    state.logOut = payload
    localStorage.removeItem("token")
    localStorage.removeItem("loginStatus")
    console.log("loged out")
  },
  removeLoginCredentials (state) {
    state.logInCredentials = {}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}