<template>
  <div class="footer-contents">
    <h3>Insta Archive</h3>
    <p>©{{ year }} Insta Archive. All Rights Reserved.</p>
  </div>
</template>

<script>
import "@/assets/styles/app-footer.css"
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
