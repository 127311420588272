<template>
  <el-container>
    <el-header>
      <AppHeader />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <el-footer>
      <AppFooter />
    </el-footer>
  </el-container>
</template>

<script>
import "./assets/styles/app.css"
import AppHeader from "./components/AppHeader.vue"
import AppFooter from "./components/AppFooter.vue"
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
}
</script>
